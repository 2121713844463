//type
import { ForgotPasswordChangePasswordFormProps } from '@components/auth/interfaces/app/view/form/ForgotPasswordChangePasswordForm';

//library
import React, { ReactElement } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

/**
 * Create the form to change the password
 *
 * @param {ForgotPasswordChangePasswordFormProps} props - The auth config and onSubmit function.
 * @returns {ReactElement} - The Forgot change password form
 */
const ForgotPasswordChangePasswordForm: React.FC<ForgotPasswordChangePasswordFormProps> = (
    props: ForgotPasswordChangePasswordFormProps
): ReactElement => {
    const initialValues = {
        code: '',
        password: '',
        confirmpw: ''
    };
    const { config, onSubmit } = props;
    const { error, username } = config.data.state;
    const { forgotPasswordChangePassword, back } =
        config.controller.useForgotPasswordChangePasswordCtrl(onSubmit);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
                code: Yup.string().min(2, 'Must be more than 2 characters').required('Required'),
                password: Yup.string()
                    .max(50, 'Must be 50 characters or less')
                    .min(8, 'Must be 8 characters or more')
                    .required('Required'),
                confirmpw: Yup.string().when('password', {
                    is: (val: string) => (val && val.length > 0 ? true : false),
                    then: Yup.string()
                        .oneOf([Yup.ref('password')], 'Both password need to be the same')
                        .required('Required')
                })
            })}
            onSubmit={async (values, { setSubmitting }) => {
                forgotPasswordChangePassword({ ...values, username, state: 'ChangePassword' });
                setSubmitting(false);
            }}>
            <Form>
                <div className="bg-white p-12 shadow-md rounded">
                    <div
                        className={`text-red-500 italic text-center py-5 ${
                            error ? 'block' : 'hidden'
                        }`}>
                        {error}
                    </div>
                    <div>
                        <label
                            className="block text-navy text-base font-medium leading-6 pt-5"
                            htmlFor="code">
                            Verification Code
                        </label>
                        <Field
                            aria-label="code"
                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                            name="code"
                            type="text"
                            placeholder="Code"
                        />
                        <div className="text-red-500 text-xs italic absolute">
                            <ErrorMessage name="code" />
                        </div>
                    </div>
                    <div>
                        <label
                            className="block text-navy text-base font-medium leading-6 pt-5"
                            htmlFor="password">
                            New Password
                        </label>
                        <Field
                            aria-label="forgot-password-new"
                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                            name="password"
                            type="password"
                            placeholder="New password"
                            autoComplete="new-password"
                        />
                        <div className="text-red-500 text-xs italic absolute">
                            <ErrorMessage name="password" />
                        </div>
                    </div>
                    <div>
                        <label
                            className="block text-navy text-base font-medium leading-6 pt-5"
                            htmlFor="confirmpw">
                            Confirm New Password
                        </label>
                        <Field
                            aria-label="confirm-password"
                            className="shadow appearance-none border rounded w-full h-12 mt-1 pl-4 py-3 font-normal leading-tight focus:outline-none focus:shadow-outline"
                            name="confirmpw"
                            type="password"
                            placeholder="Confirm new password"
                            autoComplete="new-password"
                        />
                        <div
                            data-testid="confirmpw-error"
                            className="text-red-500 text-xs italic absolute">
                            <ErrorMessage name="confirmpw" />
                        </div>
                    </div>
                </div>
                <div className="flex py-10 justify-between px-2 md:px-0">
                    <button
                        className="w-36 h-12 px-3  bg-white text-elements text-base font-medium rounded leading-tight hover:shadow-outline focus:shadow-outline border-solid border-2"
                        onClick={back}
                        type="button">
                        Back
                    </button>

                    <button
                        className="w-36 h-12 px-3 bg-elements text-white text-base font-medium  rounded focus:outline-none leading-tight hover:shadow-outline focus:shadow-outline "
                        type="submit">
                        Submit
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

export default ForgotPasswordChangePasswordForm;
