//Type
import { AuthProps, AuthConfig } from '@components/auth/interfaces/Auth';

//Library
import React, { ReactElement } from 'react';
import { Amplify } from 'aws-amplify';

//Amplify Config
import appsync from '@components/graphql/config/awsAppsync';
import authConfig from '@components/auth/config/awsCognito';

Amplify.configure({ ...appsync, Auth: authConfig, ssr: true });

//code
import Maintenance from '@components/auth/app/view/layout/Maintenance';
import Main from '@components/auth/app/view/Main';

//model
import authModel from '@components/auth/app/model/authModel';

//controller
import useControllers from '@components/auth/app/controllers/useControllers';

/**
 * Amplify auth module.
 *
 * @param {AuthProps} props - The auth props with children to show if the user is signed in.
 * @returns {ReactElement} - The login funnel or the children that we pass.
 */
const AuthStateApp: React.FC<AuthProps> = (props: AuthProps): ReactElement => {
    const { Component, pageProps } = props.lib.app;
    // Sentry: Workaround for https://github.com/vercel/next.js/issues/8592
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const err = (props.lib.app as any).err;

    const config: AuthConfig = {
        ...props,
        controller: useControllers({
            ...props,
            model: { authModel: authModel(props) }
        }),
        children: <Component {...pageProps} err={err} />,
        data: {
            state: {
                userData: { challengeName: '', challengeParam: {} },
                username: '',
                loading: false
            }
        }
    };

    // Block everything with maintenance component if environment is set to maintenance.
    if (process.env.NEXT_PUBLIC_MAINTENANCE && JSON.parse(process.env.NEXT_PUBLIC_MAINTENANCE)) {
        return <Maintenance />;
    }

    return <Main {...config} />;
};

export default AuthStateApp;
