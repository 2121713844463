//types
import { RequestPasswordInput } from '@components/auth/interfaces/app/controllers/useControllers';
import { AuthConfig } from '@components/auth/interfaces/Auth';

//library
import React, { ReactElement } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

/**
 * The request password change form after a user has been added manually by an admin
 *
 * @param {AuthConfig} config - The auth config
 * @returns {ReactElement} - The form.
 */
const RequestPasswordForm: React.FC<AuthConfig> = (config: AuthConfig): ReactElement => {
    const initialValues: RequestPasswordInput = {
        password: ''
    };
    const { changePassword, displayPassword, error, showNewPassword } =
        config.controller.useRequestPasswordCtrl(config.data.state.userData);

    return (
        <div
            slot="require-new-password"
            aria-label="request-new-password"
            className="flex justify-center w-full">
            <div className="divide-y-0 w-full md:w-2/3 lg:w-1/2 max-w-2xl">
                <div className="w-full px-6 pt-9 pb-12 bg-white rounded-lg shadow-lg">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object({
                            password: Yup.string()
                                .max(50, 'Must be 50 characters or less')
                                .min(8, 'Must be 8 characters or more')
                                .required('Required')
                        })}
                        onSubmit={async (values, { setSubmitting }) => {
                            await changePassword(values);
                            setSubmitting(false);
                        }}>
                        {({ touched, errors }) => (
                            <Form>
                                <div
                                    aria-label="new-password-header"
                                    className="text-2xl font-bold">
                                    Please enter a new password.
                                </div>
                                {error && <div className="text-red-500 italic mt-4">{error}</div>}
                                <div className="mt-6 space-y-4">
                                    <div className="w-80 flex flex-col space-y-1">
                                        <label
                                            className="text-base font-medium leading-6"
                                            htmlFor="password">
                                            Password
                                        </label>
                                        <div className="flex items-center shadow appearance-none border rounded w-full h-12 mt-1 font-normal leading-tight focus:outline-none focus:shadow-outline">
                                            <Field
                                                aria-label="new-password"
                                                className="appearance-none w-full  pl-4 py-3 leading-tight focus:outline-none"
                                                type={showNewPassword ? 'text' : 'password'}
                                                name="password"
                                                placeholder="Password"
                                                autoComplete="current-password"
                                            />
                                            <button
                                                type="button"
                                                className="mx-3 text-gray-500"
                                                onClick={displayPassword}>
                                                {showNewPassword ? (
                                                    <svg
                                                        data-testid="visible"
                                                        viewBox="0 0 512 512"
                                                        className="h-5 w-5 text-gray-500 fill-current">
                                                        <g>
                                                            <path
                                                                d="M256,122.5C116.9,122.5,9.9,245.1,9.9,245.1L0,256l9.9,10.9c0,0,97.6,111.3,227.3,121.5c6.2,0.8,12.4,1,18.8,1
                                                              c6.4,0,12.6-0.3,18.8-1c129.8-10.2,227.3-121.5,227.3-121.5L512,256l-9.9-10.9C502.1,245.1,395.1,122.5,256,122.5z M256,155.9
                                                              c36.8,0,70.6,10,100.1,23.5c10.6,17.6,16.7,37.9,16.7,60c0,60.3-45.2,109.8-103.8,116.3h-1c-4,0.2-8,0.5-12,0.5
                                                              c-4.4,0-8.7-0.3-13-0.5c-58.5-6.5-103.8-56-103.8-116.3c0-21.8,5.9-42,16.2-59.4h-0.5C184.6,166.2,218.9,155.9,256,155.9z
                                                              M256,189.3c-27.6,0-50.1,22.4-50.1,50.1s22.4,50.1,50.1,50.1s50.1-22.4,50.1-50.1S283.6,189.3,256,189.3z M110,204.9
                                                              c-2.6,11.2-4.2,22.5-4.2,34.4c0,29.3,8.3,56.6,22.9,79.8c-42-24.3-71.2-53.3-80.8-63.1C56,247.8,78.3,226,110,204.9z M402,204.9
                                                              c31.7,21.1,54,42.9,62,51.1c-9.6,9.8-38.8,38.8-80.8,63.1c14.6-23.1,22.9-50.5,22.9-79.8C406.2,227.4,404.6,216,402,204.9z"
                                                            />
                                                        </g>
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        data-testid="invisible"
                                                        viewBox="0 0 512 512"
                                                        className="h-5 w-5 text-gray-500 fill-current">
                                                        <g>
                                                            <path
                                                                d="M51.1,27.1l-24,24l103.8,103.2L316,339.4l31.8,32.3l113.1,113.1l24-24L381.1,357.1c70.6-35,118-86.9,121-90.2L512,256
                                                              l-9.9-10.9c-4.5-5-111.2-122.5-246.1-122.5c-32.7,0-63.5,7.2-91.8,17.7L51.1,27.1z M256,155.9c35.9,0,69.9,10.1,100.1,24
                                                              c10.8,18,16.7,38.3,16.7,59.4c0,30.3-11.7,58-30.8,78.7l-47.4-47.4c7-8.5,11.5-19.4,11.5-31.3c0-27.6-22.4-50.1-50.1-50.1
                                                              c-11.9,0-22.7,4.5-31.3,11.5l-33.9-33.9C211.6,160.5,233.3,155.9,256,155.9z M100.6,171c-53.5,32.9-88.2,71.2-90.7,74L0,256
                                                              l9.9,10.9c4.3,4.8,103.1,112.9,230.5,121.5c5.1,0.5,10.4,1,15.6,1s10.5-0.5,15.6-1c13.8-0.9,27.2-2.7,40.1-5.7L282.1,353
                                                              c-8.5,2-17.1,3.1-26.1,3.1c-64.4,0-116.8-52.4-116.8-116.8c0-8.9,1.2-17.5,3.1-26.1L100.6,171z M110,204.9
                                                              c-2.7,11.3-4.2,22.7-4.2,34.4c0,29,8.1,55.7,22.4,78.7c-38.2-21.9-67.4-47.6-82.4-62C58.3,243.9,80.7,223.9,110,204.9z M402,204.9
                                                              c29.3,19,51.6,39,64.1,51.1c-15,14.5-44.6,40.7-82.9,62.6c14.3-23,22.9-50.2,22.9-79.3C406.2,227.6,404.7,216.1,402,204.9z"
                                                            />
                                                        </g>
                                                    </svg>
                                                )}
                                            </button>
                                        </div>
                                        <div className="flex justify-between">
                                            <div className="text-red-500 text-xs italic">
                                                <ErrorMessage name="password" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-10">
                                    <button
                                        type="submit"
                                        disabled={
                                            !!Object.keys(touched).length &&
                                            !!Object.keys(errors).length
                                        }
                                        className={`flex items-center justify-between space-x-6 px-6 py-4 bg-light-blue rounded-md ${
                                            Object.keys(touched).length &&
                                            Object.keys(errors).length &&
                                            'opacity-30'
                                        }`}>
                                        <span
                                            aria-label="next-button"
                                            className="text-base font-semibold text-white">
                                            Next
                                        </span>
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default RequestPasswordForm;
