//types
import { State, Action } from '@components/auth/interfaces/app/controllers/useControllers';

export const initialState = {
    username: '',
    showNewPassword: false,
    loading: false,
    error: ''
};

/**
 * The reducer function to control state
 *
 * @param {State} state - The state.
 * @param {Action} action - The action and data.
 * @returns {State} - The updated state.
 */
export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'FETCH':
            state = {
                ...state,
                loading: true,
                error: ''
            };
            break;
        case 'CANCEL':
            state = {
                ...state,
                loading: false,
                error: ''
            };
            break;
        case 'ERROR':
            state = {
                ...state,
                loading: false,
                error: action.data?.error || 'Something went wrong!'
            };
            break;
        case 'SHOW':
            state = {
                ...state,
                showNewPassword: !state.showNewPassword
            };
            break;
        case 'SUCCESS':
            state = {
                ...state,
                loading: false,
                error: '',
                authState: action.data?.mounted ? action.data?.authState : state.authState,
                authData: action.data?.mounted ? action.data?.authData : state.authData,
                username: action.data?.username || state.username,
                step: action.data?.step || state.step
            };
            break;
    }

    return { ...state };
};
