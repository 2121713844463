//type
import { AuthConfig } from '@components/auth/interfaces/Auth';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';

//library
import React, { ReactElement } from 'react';
import Head from 'next/head';

//code
import Loader from '@components/lib/template/layout/Loader';
import SignInForm from '@components/auth/app/view/form/SignInForm';
import ForgotPasswordForms from '@components/auth/app/view/layout/ForgotPasswordForms';
import Redirect from '@components/auth/app/view/layout/Redirect';
import RequestPasswordForm from '@components/auth/app/view/form/RequestPasswordForm';

/**
 * Main page for auth.
 *
 * @param {AuthConfig} config - The config.
 * @returns {ReactElement} - The main page.
 */
const Main: React.FC<AuthConfig> = (config: AuthConfig): ReactElement => {
    const { authData, authState, loading, userData, setUserData } = config.controller.useMainCtrl();
    config.data.state = {
        ...config.data.state,
        authData,
        authState,
        loading,
        userData: userData as CognitoUserInterface
    };
    const { AmplifyAuthenticator } = config.lib.external;

    return (
        <>
            {/* Wrap children with Redirect if authData is available */}
            {authData && <Redirect {...config} />}
            {/* Login if no authData available */}
            {Boolean(!authData) && Boolean(!loading) && (
                <>
                    <Head>
                        <title>GetSolar AI</title>
                        <link rel="icon" href="/favicon.ico" />
                    </Head>
                    <AmplifyAuthenticator initialAuthState={AuthState.SignIn}>
                        <div
                            slot="loading"
                            className="h-screen w-view flex justify-center items-center">
                            <Loader />
                        </div>
                        <SignInForm config={config} setUserData={setUserData} />
                        <RequestPasswordForm {...config} />
                        <div slot="sign-up"></div>
                        <ForgotPasswordForms {...config} />
                    </AmplifyAuthenticator>
                </>
            )}
            {/* Loading screen */}
            {Boolean(loading) && (
                <div
                    className="h-screen w-view flex justify-center items-center"
                    aria-label="loading">
                    <Loader />
                </div>
            )}
        </>
    );
};

export default Main;
