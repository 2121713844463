//types
import { AuthConfig } from '@components/auth/interfaces/Auth';

//library
import React, { ReactElement } from 'react';
import Image from 'next/image';

/**
 * The redirect page
 *
 * @param {AuthConfig} config - The auth config.
 * @returns {ReactElement} - The redirect page.
 */
const Redirect: React.FC<AuthConfig> = (config: AuthConfig): ReactElement => {
    const { redirect, hasAccess } = config.controller.useRedirectCtrl();
    const { authData } = config.data.state;
    return (
        <>
            {authData && hasAccess(authData) ? (
                config.children
            ) : (
                <div
                    aria-label="redirect"
                    className="flex flex-col h-screen w-full px-4 items-center justify-center">
                    <Image src="/solarai_logo.png" alt="GetSolar AI" width="240" height="55" />
                    <p className="text-lg md:text-xl lg:text-2xl text-center mt-4 text-elements">
                        You do not have access to this website.
                    </p>
                    <button
                        aria-label="redirect-button"
                        onClick={redirect}
                        className="rounded-md px-4 py-2 mt-3 text-sm leading-5 text-gray-200 bg-elements hover:bg-navy focus:outline-none focus:bg-azure transition duration-150 ease-in-out">
                        You may want to go to search.getsolar.ai instead
                    </button>
                </div>
            )}
        </>
    );
};

export default Redirect;
