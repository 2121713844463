/** Appsync config */

const awsappsync = {
    aws_project_region: process.env.NEXT_PUBLIC_AWS_REGION,
    aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQLENDPOINT,
    aws_appsync_region: process.env.NEXT_PUBLIC_AWS_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
};

export default awsappsync;
