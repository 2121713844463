//library
import React, { ReactElement } from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

//type
import { HeaderProps } from '@components/auth/interfaces/app/view/layout/Header';

/**
 * The Auth Header.
 *
 * @param {HeaderProps} props - The sub title for the header
 * @returns {ReactElement} - The header for the auth funnels
 */
const Header: React.FC<HeaderProps> = ({ sub_title = ' ' }: HeaderProps): ReactElement => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex justify-center flex-col">
                <div className="m-auto flex items-center gap-x-4">
                    <Image src="/solarai_logo.png" alt="GetSolar AI" width={245} height={55} />
                    <Image src={`/${t('flag')}`} height={36} width={36} alt="Country flag" />
                </div>
                <span className="text-gray-500 font-medium text-lg text-center">{sub_title}</span>
            </div>
        </>
    );
};

export default Header;
