//type
import { LoaderProps } from '@components/lib/interfaces/template/layout/Loader';

//Library
import React from 'react';

/**
 * The loader.
 *
 * @param {LoaderProps} props - The props.
 * @returns {React.ReactElement} - The loader.
 */
const Loader: React.FC<LoaderProps> = ({ data }: LoaderProps): React.ReactElement => (
    <div aria-label="loader" className={'flex justify-center items-center'} key={0}>
        <svg
            aria-label="loader-icon"
            xmlns="http://www.w3.org/2000/svg"
            className={data?.style || 'h-8 w-8'}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid">
            <rect x={48} y={22.5} rx={2} ry={2.25} width={4} height={15}>
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.7575757575757576s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(30 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.6887052341597797s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(60 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.6198347107438017s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(90 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.5509641873278237s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(120 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.48209366391184577s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(150 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.4132231404958678s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(180 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.34435261707988984s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(210 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.27548209366391185s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(240 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.2066115702479339s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(270 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.13774104683195593s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(300 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="-0.06887052341597796s"
                    repeatCount="indefinite"
                />
            </rect>
            <rect
                x={48}
                y={22.5}
                rx={2}
                ry={2.25}
                width={4}
                height={15}
                transform="rotate(330 50 50)">
                <animate
                    attributeName="opacity"
                    values="1;0"
                    keyTimes="0;1"
                    dur="0.8264462809917356s"
                    begin="0s"
                    repeatCount="indefinite"
                />
            </rect>
        </svg>
    </div>
);

export default Loader;
