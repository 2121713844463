//type
import { AuthProps } from '@components/auth/interfaces/Auth';

//library
import React, { ReactElement } from 'react';
import type { AppProps /*, AppContext */ } from 'next/app';
import * as Sentry from '@sentry/node';
import { useRouter } from 'next/router';
import { Auth, Hub } from 'aws-amplify';
import {
    UI_AUTH_CHANNEL,
    AUTH_STATE_CHANGE_EVENT,
    onAuthUIStateChange
} from '@aws-amplify/ui-components';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';

//css
import '../styles/tailwind.css';

//code
import AuthStateApp from '@components/auth/AuthStateApp';
import useProgressBar from '@components/helpers/layout/useProgressBar';
import i18n from '@components/helpers/helpers/i18n';

//Sentry
/** Follow: https://leerob.io/blog/configuring-sentry-for-nextjs-apps */
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        normalizeDepth: 20
    });
}

/**
 * The app function.
 *
 * @param {AppProps} appProps - The props of the page.
 * @returns {ReactElement} - The page we want to navigate.
 */
function MyApp(appProps: AppProps): ReactElement {
    const router = useRouter();
    i18n.changeLanguage(router.locale);

    const props: AuthProps = {
        lib: {
            app: { ...appProps },
            external: {
                router,
                Auth,
                Hub,
                UI_AUTH_CHANNEL,
                AUTH_STATE_CHANGE_EVENT,
                onAuthUIStateChange,
                AmplifyAuthenticator
            }
        }
    };

    useProgressBar();

    return <AuthStateApp {...props} />;
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
