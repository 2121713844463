//type
import { AuthConfig } from '@components/auth/interfaces/Auth';

//library
import React, { ReactElement } from 'react';

//code
import Header from '@components/auth/app/view/layout/Header';
import ForgotPasswordCodeForm from '@components/auth/app/view/form/ForgotPasswordCodeForm';
import ForgotPasswordChangePasswordForm from '@components/auth/app/view/form/ForgotPasswordChangePasswordForm';

/**
 * The forget password form
 *
 * @param {AuthConfig} config - The auth config.
 * @returns {ReactElement} - The forgot passowrd form depending on which step we are on.
 */
const ForgotPasswordForms: React.FC<AuthConfig> = (config: AuthConfig): ReactElement => {
    const { forgotPassword, username, step, error } = config.controller.useForgotPasswordCtrl();
    config.data.state = {
        ...config.data.state,
        error: error,
        username: username
    };

    return (
        <div slot="forgot-password" className="flex justify-center w-full">
            <div className="divide-y-0 md:w-2/3 lg:w-1/2 mt-24 w-full max-w-2xl">
                <Header sub_title="Forgot Password" />
                <div className="pt-12">
                    {Boolean(!step) && (
                        <ForgotPasswordCodeForm
                            config={config}
                            onSubmit={(values, error) => forgotPassword(values, error)}
                        />
                    )}
                    {Boolean(step) && !!username && (
                        <ForgotPasswordChangePasswordForm
                            config={config}
                            onSubmit={(values, error) => forgotPassword(values, error)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordForms;
